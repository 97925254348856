import { Fragment, FunctionComponent } from 'react'
import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction } from '~/src/utils/env'
import { Helmet } from 'react-helmet-async'
import { redirectToThanks } from '../BookADemo/redirectUtils'
import ContactSalesWrapper from './ContactSalesWrapper'
import VerticalizedWrapper from './VerticalizedCampaign/VerticalizedWrapper'

import { CHILIPIPER_SDK_URL } from '~/src/constants'

const ContactSales: FunctionComponent = () => {
  const utm_campaign = new URLSearchParams(window.location.search).get('utm_campaign')
  const isVerticalizedCampaign = utm_campaign?.toLowerCase().startsWith('verticalized_gtm')
  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <Helmet>
        {/* Include chilipiper script */}
        <script src={CHILIPIPER_SDK_URL} type='text/javascript' />
      </Helmet>
      {isVerticalizedCampaign ? (
        <VerticalizedWrapper onSubmit={redirectToThanks} />
      ) : (
        <ContactSalesWrapper onSubmit={redirectToThanks} />
      )}
    </Fragment>
  )
}

export default ContactSales
