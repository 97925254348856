import { HTTPError } from 'ky'
import {
  ApiLeadModel,
  LeadExperiment,
  LeadModel,
  PreApplicationStep,
  RedirectToPath,
  RoutingDirection,
} from '~/src/models/leads/LeadModel/types'
import { parse, stringify } from 'query-string'
import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet-async'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { RigidPage } from '~/src/components/RigidPage'
import { Loading } from '~/src/components/Loading'
import { useCreateLead } from '~/src/hooks/leads'
import { trackEvent } from '~/src/utils/analytics'
import { baseWebUrl, isDevelopment, isProduction } from '~/src/utils/env'
import { logError } from '~/src/utils/errors'

import { StepWrapperAsFullPage } from './StepWrapperAsFullPage'
import { PandoraTrackingPixel, redditTrackingPixelForMQLs } from './TrackingPixels'
import {
  CompanySize,
  GLA,
  LESS_THAN_25k,
  MonthlySpend,
  SalesDemo,
  Unqualified,
  DemoRequested,
  SelfService,
} from './pages'
import { getCurrentStep, getPreApplicationRouteName, preApplicationSteps } from './stepUtil'
import { useFetchLeadModel, useUpdateLeadModel } from '~/src/models/leads/LeadModel/hooks'
import { LeadContext } from '~/src/models/leads/LeadModel/context'
import { InterestedProducts } from './pages/InterestedProducts'
import { parseCookie } from '~/src/utils/analytics/pageEnrichment'
import {
  ChilipiperEvent,
  getChilipiperEventPayload,
  receiveChiliPiperMessage,
} from '~/src/utils/analytics/handleChilipiperEvent'
import { isAnyOf } from '~/src/utils/compare'
import pollPromise from '~/src/utils/poll'
import { RyuLoadingOverlay } from '@ramp/ryu'
import { SalesDemoForm } from '~/src/routes/UserQualification/pages/sales-demo-type'

export const StepContent: FunctionComponent = () => {
  const parentMatch = useRouteMatch()

  const [errorCreatingLead, setErrorCreatingLead] = useState(false)

  const [isUpdating, setIsUpdating] = useState(false)
  const [bookedMeeting, setBookedMeeting] = useState(false)

  const createLead = useCreateLead()

  const location = useLocation()
  const { search } = location
  const {
    force: raw_force,
    email: queryStringEmail,
    experiment: raw_experiment,
    // project-contact-sales-flow
    source: contact_sales_source,
  } = parse(search)

  const [cookies] = useCookies()
  // If the email is not passed via a query param, then check if it was saved as a cookie
  // If a user visits prequal directly with a + sign in email, decoder converts it to a space, so we need to convert it back here
  const [email] = useState(
    ((queryStringEmail as string) ?? cookies.leadEmail)?.replace(' ', '+') ?? undefined
  )
  const overriding_to_sales = raw_force === 'true'
  const use_contact_sales_experience = Boolean(contact_sales_source)

  const [leadId, setLeadId] = useState<string | null>(null)
  const [clickedBack, setClickedBack] = useState(false)

  const { lead, setLead, setHydratedLead } = useContext(LeadContext)

  const updateLead = useUpdateLeadModel()
  const fetchLeadModel = useFetchLeadModel()

  const routing_direction_changed_for_contact_sales = !!lead?.experiments?.some(
    (experiment) =>
      experiment === LeadExperiment.ContactSalesStandardSDR ||
      experiment === LeadExperiment.ContactSalesScaledSDR
  )

  const generateLeadAndPollForCreation = useCallback(
    async (email: string) => {
      // make a partial lead
      const partialLead = await createLead(email, { discovery_method: 'see_a_demo' })
      if (!partialLead) {
        throw new Error('Lead creation failed')
      }

      const leadId = partialLead.id
      // Poll for lead creation
      // This will error if we try more than 5 times
      // This error will bubble up and be caught in the catch block
      const lead = await pollPromise(() => fetchLeadModel(leadId), {
        interval: 1000,
        maxAttempts: 5,
        condition: (lead) => !!lead?.routing_direction,
      })

      return { ...partialLead, ...lead }
    },
    [createLead, fetchLeadModel]
  )

  const updateLeadObject = useCallback(
    async (updateLeadModelPayload: Partial<LeadModel>) => {
      if (!lead || isUpdating) {
        return
      }

      setIsUpdating(true)
      try {
        const updatedLead = await updateLead(lead.id, updateLeadModelPayload)
        setLead((prevLeadModel) => {
          if (prevLeadModel) {
            return { ...prevLeadModel, ...updateLeadModelPayload, ...updatedLead }
          }

          return prevLeadModel
        })
      } catch {
        // Default to control experiment on failure
        setLead((prevLead) => {
          if (prevLead) {
            return {
              ...prevLead,
              experiments: prevLead.experiments
                ? [...prevLead.experiments, LeadExperiment.QualifiedChatBotControl]
                : [LeadExperiment.QualifiedChatBotControl],
            }
          }

          return prevLead
        })
      }

      setIsUpdating(false)
    },
    [isUpdating, lead, setLead, updateLead]
  )

  const salesQualifiedTrackingPixels = isProduction ? (
    <Fragment>
      {redditTrackingPixelForMQLs}
      <PandoraTrackingPixel pandoraStage='registration' />
    </Fragment>
  ) : null

  useEffect(() => {
    ;(async () => {
      // Redirect people who land on qualification flow without email back to Webflow
      if (!cookies?.leadId && !leadId) {
        if (!email) {
          return window.location.replace('https://ramp.com')
        }
      }

      if (!email || email === lead?.company_email) {
        return
      }

      try {
        let response: Readonly<ApiLeadModel> | undefined
        try {
          const createLeadFunction = use_contact_sales_experience
            ? generateLeadAndPollForCreation
            : createLead

          response = await createLeadFunction(email)

          if (response) {
            setHydratedLead(response)
          }
        } catch (e) {
          setErrorCreatingLead(true)
          if (e instanceof HTTPError) {
            const responseBody = await e.response.json()
            if (responseBody.error_v2?.error_code === 'LEAD_0001') {
              // redirect to /sign-in if already a user so they don't fill out the prequal form
              window.location.replace(`/sign-in?email=${encodeURIComponent(email)}`)
              return
            }
          }

          logError.veryHigh(e, { tags: { owner: 'rcox' } })
        }

        if (!response) {
          return
        }

        setLeadId(response.id)
        /*
          we're directly using the "skipped_prequal" value from the response instead of
          using `lead.skipped_prequal` to ensure the value is not stale from Hook rendering order.
          */
        if (response.skipped_prequal) {
          await trackPrequal()
        }
      } catch (e) {
        logError.high(e, {
          tags: { owner: 'gchoy_ramp' },
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email])

  // project-contact-sales-flow - update experiment tag if necessary (only for leads that need it)
  useEffect(() => {
    if (!use_contact_sales_experience) {
      return
    }

    // if the lead doesn't exist yet, ignore
    if (!lead?.routing_direction || !lead?.id) {
      return
    }

    // If the lead has already been assigned an experiment, do nothing
    if (
      lead?.experiments?.some(
        (experiment) =>
          experiment === LeadExperiment.ContactSalesStandardSDR ||
          experiment === LeadExperiment.ContactSalesScaledSDR
      )
    ) {
      return
    }

    if (
      isAnyOf(lead.routing_direction, RoutingDirection.SALES_QUALIFIED, RoutingDirection.SALES_OPTIONAL)
    ) {
      updateLeadObject({
        experiment: LeadExperiment.ContactSalesStandardSDR,
      })
    } else if (lead.routing_direction === RoutingDirection.SELF_SERVICE_NO_PREQUAL) {
      updateLeadObject({ experiment: LeadExperiment.ContactSalesScaledSDR })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    use_contact_sales_experience,
    routing_direction_changed_for_contact_sales,
    lead?.id,
    lead?.routing_direction,
    updateLeadObject,
  ])

  // Chilipiper event handler
  useEffect(() => {
    if (window.ChiliPiper) {
      window.addEventListener(
        'message',
        (event: ChilipiperEvent) =>
          receiveChiliPiperMessage({
            event,
            lead,
            cookies,
          }),
        false
      )
    }
  }, [lead, cookies])

  const currentStep = useMemo(
    () => getCurrentStep(overriding_to_sales, lead),
    [lead, overriding_to_sales]
  )

  function expediateLeadRouting(lead: LeadModel) {
    const currentStep = getCurrentStep(overriding_to_sales, lead)
    gotoStep({
      step: currentStep,
    })
  }

  useEffect(() => {
    gotoStep({
      step: currentStep,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  async function trackPrequal() {
    if (lead) {
      window.clearbit?.identify(lead.id, {
        email,
        stage: currentStep,
      })

      // Updates Hubspot contact field
      await window.analytics?.identify({
        email,
        pre_app_flow_completed_result: currentStep,
      })

      const stepContentResultPayload = {
        email,
        result: currentStep,
        experiment: lead.experiment,
      }

      trackEvent('Pre Application Flow Completed', stepContentResultPayload)

      // The following track calls are used for ad attribution - we don't want to count those w LESS_THAN_25k as valid conversions
      if (lead.bank_balance !== LESS_THAN_25k) {
        if (currentStep === PreApplicationStep.SALES_QUALIFIED) {
          trackEvent('Lead is Sales Qualified', stepContentResultPayload)
        }

        if (currentStep === PreApplicationStep.SELF_SERVICE_QUALIFIED) {
          trackEvent('Lead is Self Service Qualified', stepContentResultPayload)
        }

        if (
          currentStep === PreApplicationStep.SALES_QUALIFIED ||
          currentStep === PreApplicationStep.SELF_SERVICE_QUALIFIED
        ) {
          trackEvent('Lead is Sales Qualified or Self Service Qualified', stepContentResultPayload)
        }
      }
    }
  }

  const getPath = (step: PreApplicationStep | null) =>
    `${parentMatch?.path}/${getPreApplicationRouteName(step)}`

  const history = useHistory()

  function getFullRedirectPath({ path, location }: { path: RedirectToPath; location?: string }): string {
    const parsedCookies = parseCookie(document.cookie) // workaround because referencing cookies from useCookie hook was returning undefined

    const queryParams = stringify({
      rc: parsedCookies.rc ?? parsedCookies.initial_rc ?? undefined,
      referral_location:
        parsedCookies.referral_location ?? parsedCookies.initial_referral_location ?? undefined,
      partner_promo:
        parsedCookies.partner_promo ??
        parsedCookies.initial_partner_promo ??
        parsedCookies.promo ??
        undefined,
      ...(location && { location }),
      allow_personal_email_signup: parsedCookies.allow_personal_email_signup ?? undefined,
    })

    if (path === PreApplicationStep.SELF_SERVICE_QUALIFIED) {
      return `${baseWebUrl}/qualification/self-service?email=${encodeURIComponent(email)}${
        queryParams ? '&'.concat(queryParams) : ''
      }`
    }

    return `${baseWebUrl}/qualification/demo-requested?email=${encodeURIComponent(email)}${
      queryParams ? '&'.concat(queryParams) : ''
    }`
  }

  const trackPrequalAndRedirect = useCallback(
    async (path: RedirectToPath) => {
      const isSalesQualified = currentStep === PreApplicationStep.SALES_QUALIFIED
      const fullPath = getFullRedirectPath({
        path,
        location: isSalesQualified ? 'sales-qualified' : undefined,
      })
      await trackPrequal()
      window.location.replace(fullPath)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lead?.experiment]
  )

  function getChilipiperQueue({
    use_contact_sales_experience,
    lead,
    interested_in_products,
  }: {
    use_contact_sales_experience: boolean
    lead: any
    interested_in_products: string[]
  }) {
    if (
      use_contact_sales_experience &&
      lead?.experiments?.includes(LeadExperiment.ContactSalesScaledSDR)
    ) {
      return 'sdr-queue-book-a-demo---scaled'
    } else if (!interested_in_products.includes('interested_in_corporate_cards')) {
      return 'p2p-concierge-queue'
    }

    return 'sdr-queue'
  }

  const gotoStep = ({ step }: { step: PreApplicationStep | null }) => {
    if (!step) {
      throw new Error('No step passed in')
    }

    const routeName = getPreApplicationRouteName(step)
    const queryParams = stringify({
      email,
      rc: cookies.rc ?? cookies.initial_rc ?? undefined,
      referral_location: cookies.referral_location ?? cookies.initial_referral_location ?? undefined,
      partner_promo:
        cookies.partner_promo ?? cookies.initial_partner_promo ?? cookies.promo ?? undefined,
      force: overriding_to_sales || undefined,
      experiment: raw_experiment ?? lead?.experiment,
      source: contact_sales_source ?? undefined,
    })

    // project-contact-sales-flow
    if (step === PreApplicationStep.SELF_SERVICE_QUALIFIED && use_contact_sales_experience) {
      return
    }

    history.push(`${parentMatch.path}/${routeName}?${queryParams}`)
    window.scrollTo(0, 0)
  }

  const renderStep = (step: PreApplicationStep | null): ReactNode => {
    if (!email) {
      if (isDevelopment) {
        throw Error('need to provide an ?email= param')
      } else {
        window.location.replace('/')
        return
      }
    }

    if (!lead && !errorCreatingLead) {
      // In the normal prequal flow, the lead is supposed to have entered their email already
      return <Loading />
    }

    switch (step) {
      case null: // fallthrough here
      case PreApplicationStep.COMPANY_SIZE:
        return (
          <CompanySize
            onChange={async (value) => {
              setLead((prevLead) => {
                if (prevLead) {
                  return { ...prevLead, has_employee_count: true, employee_count: value }
                }

                return prevLead
              })

              await updateLeadObject({ employee_count: value })
              if (!lead || clickedBack) {
                gotoStep({ step: PreApplicationStep.MONTHLY_SPEND })
              }

              if (lead?.skipped_prequal) {
                expediateLeadRouting(lead)
              }
            }}
          />
        )

      case PreApplicationStep.MONTHLY_SPEND:
        return (
          <MonthlySpend
            onClickedBack={() => setClickedBack(true)}
            onChange={async (value) => {
              setLead((prevLead) => {
                if (prevLead) {
                  return {
                    ...prevLead,
                    estimated_monthly_spend: value,
                    has_estimated_monthly_spend: true,
                  }
                }

                return prevLead
              })

              await updateLeadObject({ estimated_monthly_spend: value })
              if (!lead || clickedBack) {
                gotoStep({ step: PreApplicationStep.GLA })
              }

              if (lead?.skipped_prequal) {
                expediateLeadRouting(lead)
              }
            }}
          />
        )

      case PreApplicationStep.GLA:
        return (
          <GLA
            onClickedBack={() => setClickedBack(true)}
            onChange={async (bank_balance) => {
              // project-contact-sales-flow
              // If the lead is in the Contact Sales flow and has a bank balance, we need to update the experiment tag, and then go to the sales qualified step
              if (lead && use_contact_sales_experience && bank_balance !== LESS_THAN_25k) {
                setLead((prevLead) => {
                  if (prevLead) {
                    return { ...prevLead, has_bank_balance: true, bank_balance }
                  }

                  return prevLead
                })
                await updateLeadObject({
                  bank_balance,
                  experiment: LeadExperiment.ContactSalesScaledSDR,
                })
                gotoStep({ step: PreApplicationStep.SALES_QUALIFIED })
                return
              }

              // we define "completed prequal" for all leads as answering the GLA question.
              if (lead) {
                setLead((prevLead) => {
                  if (prevLead) {
                    return { ...prevLead, has_bank_balance: true, bank_balance }
                  }

                  return prevLead
                })
                await updateLeadObject({ bank_balance })
              }

              if (!lead || clickedBack) {
                if (bank_balance === LESS_THAN_25k) {
                  gotoStep({ step: PreApplicationStep.UNQUALIFIED })
                } else {
                  gotoStep({ step: PreApplicationStep.SELF_SERVICE_QUALIFIED })
                }
              }

              if (lead?.skipped_prequal) {
                expediateLeadRouting(lead)
              }
            }}
          />
        )

      case PreApplicationStep.INTERESTED_PRODUCTS:
        return (
          <InterestedProducts
            onClickedBack={() => setClickedBack(true)}
            onChange={async (selectedOptions) => {
              await updateLeadObject(selectedOptions)

              if (!lead || clickedBack) {
                gotoStep({ step: PreApplicationStep.SELF_SERVICE_QUALIFIED })
              }

              if (lead?.skipped_prequal) {
                expediateLeadRouting(lead)
              }
            }}
          />
        )

      case PreApplicationStep.UNQUALIFIED:
        return <Unqualified />

      case PreApplicationStep.SALES_QUALIFIED:
        return (
          <RigidPage>
            {salesQualifiedTrackingPixels}

            <SalesDemo
              bookedMeeting={bookedMeeting}
              onSubmit={async (data: SalesDemoForm) => {
                try {
                  if (!lead) {
                    throw new Error('Missing Lead during Sales Demo form submission.')
                  }

                  const { first_name, last_name, company: company_name, interested_in_products } = data

                  updateLeadObject({
                    first_name,
                    last_name,
                    company_name,
                    interested_in_corporate_cards: interested_in_products.includes(
                      'interested_in_corporate_cards'
                    ),
                    interested_in_bill_payments: interested_in_products.includes(
                      'interested_in_bill_payments'
                    ),
                    interested_in_procurement: interested_in_products.includes(
                      'interested_in_procurement'
                    ),
                    interested_in_treasury: interested_in_products.includes('interested_in_treasury'),
                  })

                  const eventPayload = getChilipiperEventPayload({ lead, cookies })

                  if (isProduction) {
                    if (window.ChiliPiper) {
                      trackEvent('Chilipiper Widget - Calendar Requested', eventPayload)
                      window.ChiliPiper.submit(
                        'ramp-com',
                        getChilipiperQueue({
                          use_contact_sales_experience,
                          lead,
                          interested_in_products: data.interested_in_products,
                        }),
                        {
                          lead: {
                            FirstName: data.first_name,
                            LastName: data.last_name,
                            Email: lead?.company_email,
                          },
                          onRouted: () => {
                            trackEvent('Chilipiper Widget - Calendar Shown', eventPayload)
                          },
                          onSuccess: async () => {
                            updateLeadObject({
                              requested_demo: true,
                              sales_demo_booked_at: new Date().toISOString(),
                            })
                            // Change to success state for v2
                            setBookedMeeting(true)
                            trackEvent('Chilipiper Widget - Meeting Successfully Booked', eventPayload)
                          },
                          onClose: () => {
                            trackEvent(
                              'Chilipiper Widget - User Exited Without Booking Meeting',
                              eventPayload
                            )
                          },
                        }
                      )
                    } else {
                      trackEvent(
                        "Unable to Render Chilipiper Widget due to User's Ad Blocker",
                        eventPayload
                      )
                    }
                  }
                } catch (e) {
                  logError.high(e, {
                    tags: { owner: 'rshen_ramp' },
                  })
                }
              }}
              onOverride={() => trackPrequalAndRedirect(PreApplicationStep.SELF_SERVICE_QUALIFIED)}
            />
          </RigidPage>
        )

      case PreApplicationStep.SELF_SERVICE_QUALIFIED:
        return <SelfService />

      case PreApplicationStep.DEMO_REQUESTED:
        return <DemoRequested />
    }
  }

  const wrapInRigidPage = !(
    currentStep === PreApplicationStep.DEMO_REQUESTED ||
    currentStep === PreApplicationStep.SELF_SERVICE_QUALIFIED
  )

  return (
    <StepWrapperAsFullPage wrapInRigidPage={wrapInRigidPage}>
      <Switch>
        {preApplicationSteps.map((step) => (
          <Route key={getPath(step)} path={getPath(step)}>
            <Helmet>
              <title>Ramp</title>
            </Helmet>
            {isUpdating ? <RyuLoadingOverlay /> : renderStep(step)}
          </Route>
        ))}
      </Switch>
    </StepWrapperAsFullPage>
  )
}
