import { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import AngelListStackLogo from '~/src/images/logo-angellist-stack.svg'
import QuantaLogo from '~/src/images/logo-quanta.png'

import { RyuImage, RyuText } from '@ramp/ryu'

import { RigidLayout } from '~/src/components/RigidLayout'
import { UsernameSignUp } from '~/src/components/UsernameSignUp'
import { SalesLeadSignUpModel, useSalesLeadData } from '~/src/hooks/useSalesLeadData'
import { isProduction } from '~/src/utils/env'
import { LeadProvider } from '~/src/models/leads/LeadModel/provider'

export function SalesLeadIcon({ salesLeadData }: { salesLeadData: SalesLeadSignUpModel | undefined }) {
  if (!salesLeadData) {
    return null
  }

  switch (salesLeadData.source) {
    case 'AngelList':
      return <RyuImage src={AngelListStackLogo} alt='AngelList Stack' />
    case 'Quanta':
      return <RyuImage src={QuantaLogo} alt='Quanta' />
  }
}

export function UserSignUpPage() {
  const salesLeadData = useSalesLeadData()

  useEffect(() => {
    document.getElementById('hyperise-script')?.addEventListener('load', () => {
      window.hyperise.init()
    })
  }, [])

  return (
    <Fragment>
      <Helmet>
        {isProduction && (
          <script
            id='hyperise-script'
            src='https://app.hyperise.io/snippets/r/v2/BBgvVIfgI.js?v=1.0.0'
          />
        )}
      </Helmet>
      <LeadProvider shouldGetOrCreateLead={true}>
        <RigidLayout pageTitle='Sign Up' align='center'>
          <SalesLeadIcon salesLeadData={salesLeadData} />

          <RyuText.h2 align='center'>Apply for Ramp</RyuText.h2>

          <UsernameSignUp />
        </RigidLayout>
      </LeadProvider>
    </Fragment>
  )
}
