import { LeadModel } from './types'
import { useState, ReactNode, FunctionComponent, useEffect } from 'react'
import { LeadContext } from './context'
import { useCreateLead } from '~/src/hooks/leads/useCreateLead'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
import { parse } from 'query-string'
import { HTTPError } from 'ky'
import { logError } from '~/src/utils/errors'
import { useFetchLeadModel } from '~/src/models/leads/LeadModel/hooks'

type Props = {
  children: ReactNode
  shouldGetOrCreateLead?: boolean
}

export const LeadProvider: FunctionComponent<Props> = ({ children, shouldGetOrCreateLead = false }) => {
  const [lead, setLeadState] = useState<null | LeadModel>(null)
  const [isLeadHydrated, setIsLeadHydrated] = useState(false)

  /**
   * Fix for #_inc-09-06-large-scan-of-leads-endpoint-inc16244
   *
   * Helper function to set default values for the name fields when hydrating from backend
   *
   * TODO: Remove this, eventually frontend will not rely on these name fields from the backend
   */
  const setHydratedLead = (
    newLead: LeadModel | null | ((prevLead: LeadModel | null) => LeadModel | null)
  ) => {
    setLeadState((prevLead) => {
      const updatedLead = typeof newLead === 'function' ? newLead(prevLead) : newLead
      if (updatedLead) {
        return {
          ...updatedLead,
          employee_count: undefined,
          has_employee_count: !!updatedLead.employee_count,
          estimated_monthly_spend: undefined,
          has_estimated_monthly_spend: !!updatedLead.estimated_monthly_spend,
          bank_balance: undefined,
          has_bank_balance: !!updatedLead.bank_balance,
          company_name: undefined,
          has_company_name: !!updatedLead.company_name,
          first_name: undefined,
          has_first_name: !!updatedLead.first_name,
          last_name: undefined,
          has_last_name: !!updatedLead.last_name,
        }
      }

      return null
    })
  }

  const getOrCreateLead = useCreateLead()
  const fetchLead = useFetchLeadModel()

  const [cookies] = useCookies()
  const location = useLocation()
  const { search } = location
  const { email: queryStringEmail } = parse(search)
  const [email] = useState(
    ((queryStringEmail as string) ?? cookies.leadEmail)?.replace(' ', '+') ?? undefined
  )

  useEffect(() => {
    ;(async () => {
      // Feb 20, 2025: Currently, other places where the LeadProvider is used in acq-web have their own logic
      // for getting or creating, then setting the lead. Ideally we have them use this default logic, but that
      // will take time to refactor.
      if (!shouldGetOrCreateLead) {
        return
      }

      if (!cookies?.leadId && !email) {
        logError.medium('No lead id or email found in cookies or query string')
        return window.location.replace('https://ramp.com')
      }

      try {
        const response = cookies?.leadId
          ? await fetchLead(cookies?.leadId) // Fetches a specific lead by id
          : await getOrCreateLead(email) // Searches for a lead by email. Creates a new lead if it doesn't exist.

        if (response) {
          setHydratedLead(response)
        }
      } catch (e) {
        if (e instanceof HTTPError && (await e.response.json()).error_v2?.error_code === 'LEAD_0001') {
          // redirect to /sign-in if already a user so they don't fill out the prequal form
          window.location.replace(`/sign-in?email=${encodeURIComponent(email)}`)
          return
        }

        logError.high(e, { tags: { owner: 'rshen_ramp' } })
      }
    })()
  }, [cookies?.leadId, email, shouldGetOrCreateLead, getOrCreateLead, fetchLead])

  return (
    <LeadContext.Provider
      value={{
        lead,
        setLead: setLeadState,
        setHydratedLead,
        isLeadHydrated,
        setIsLeadHydrated,
      }}
    >
      {children}
    </LeadContext.Provider>
  )
}
