import { Fragment, FunctionComponent, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

import { Loading } from '~/src/components/Loading'
import { useAttributionCookies } from '~/src/hooks/useAttributionCookies'
import { clearbitScriptProd } from '~/src/scripts/clearbit'
import { GoogleTagManager } from '~/src/scripts/gtm'
import { isProduction, isQA } from '~/src/utils/env'

import { StepContent } from './StepContent'
import { LeadProvider } from '~/src/models/leads/LeadModel/provider'
import { CHILIPIPER_SDK_URL } from '~/src/constants'

export const UserQualification: FunctionComponent = () => {
  const areCookiesSet = useAttributionCookies()

  if (!areCookiesSet) {
    return <Loading />
  }

  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <Helmet>
        {isProduction && (
          <Fragment>
            <script>{clearbitScriptProd}</script>
            <script
              id='hs-script-loader'
              async={true}
              defer={true}
              src='https://js.hs-~/src/scripts.com/20122812.js'
            />
          </Fragment>
        )}
        {isQA && (
          <script
            id='hs-script-loader'
            async={true}
            defer={true}
            src='https://js.hs-~/src/scripts.com/20710031.js'
          />
        )}
        <script src={CHILIPIPER_SDK_URL} type='text/javascript' />
      </Helmet>

      <Suspense fallback={<Loading />}>
        <LeadProvider>
          <StepContent />
        </LeadProvider>
      </Suspense>
    </Fragment>
  )
}
